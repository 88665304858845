import * as React from 'react';
import Layout from '../components/Layout';

//CSS
import '../styles/index.scss';
import '../styles/grids.scss';
import '../styles/particles.scss';
import '../styles/buttons.scss';
import '../styles/elements.scss';
import ContactForm from '../components/ContactForm';
import SEO from '../components/seo';

// markup
const ContactPage = () => {
  const props = {
    seoTitle: 'Contact Us',
    seoDescription:
      'Interested in how Strategix Technology Solutions can help accelerate your digital transformation journey? Contact us and one of our experts will be in touch.',
  };

  return (
    <Layout>
      <SEO
        title={props.seoTitle}
        description={props.seoDescription}
        // keywords={[]}
        lang="en-gb"
      />
      <main>
        {/* Home Hero */}
        <section>
          <div className="hero" id="contact">
            <div className="flex-col-c">
              <h1>Contact Us</h1>
              <p>Get in touch with us today!</p>
            </div>
          </div>
        </section>

        {/* Contact */}
        <section className="half">
          <ContactForm />
        </section>
      </main>
    </Layout>
  );
};

export default ContactPage;
